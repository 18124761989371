import React from 'react'

const GoogleDriveIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 6L6 26.5L14.5 41.5L26 21L17.5 6Z" fill="#4285F4" />
            <path d="M30.5 6H17.5L26 21H39L30.5 6Z" fill="#0F9D58" />
            <path d="M14.5 41.5H33.5L42 26.5H23L14.5 41.5Z" fill="#F4B400" />
            <path d="M42 26.5L33.5 41.5L26 21H39L42 26.5Z" fill="#DB4437" />
        </svg>
    )
}

export default GoogleDriveIcon
import React from 'react'

const Free = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0H0L12.5 12.5H0L12.5 25H25L12.5 12.5H25L12.5 0Z" fill="url(#paint0_linear_1639_2113)" fill-opacity="0.7" />
            <defs>
                <linearGradient id="paint0_linear_1639_2113" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C0DBFF" />
                    <stop offset="1" stop-color="#1677F7" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Free
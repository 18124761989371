import React from 'react'

const Standered = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 4.20561C11.5967 4.20561 10.8645 4.93785 10.8645 5.84113H6.65887C6.65887 2.61516 9.27404 0 12.5 0C15.726 0 18.3411 2.61516 18.3411 5.84113C18.3411 9.06709 15.726 11.6822 12.5 11.6822V7.47664C13.4032 7.47664 14.1355 6.74439 14.1355 5.84113C14.1355 4.93785 13.4032 4.20561 12.5 4.20561ZM20.7944 12.5C20.7944 11.5967 20.0621 10.8645 19.1589 10.8645V6.65887C22.3849 6.65887 25 9.27404 25 12.5C25 15.726 22.3849 18.3411 19.1589 18.3411C15.9329 18.3411 13.3177 15.726 13.3177 12.5H17.5234C17.5234 13.4032 18.2556 14.1355 19.1589 14.1355C20.0621 14.1355 20.7944 13.4032 20.7944 12.5ZM5.84113 14.1355C4.93785 14.1355 4.20561 13.4032 4.20561 12.5C4.20561 11.5967 4.93785 10.8645 5.84113 10.8645C6.74439 10.8645 7.47664 11.5967 7.47664 12.5H11.6822C11.6822 9.27404 9.06709 6.65887 5.84113 6.65887C2.61516 6.65887 0 9.27404 0 12.5C0 15.726 2.61516 18.3411 5.84113 18.3411V14.1355ZM12.5 20.7944C13.4032 20.7944 14.1355 20.0621 14.1355 19.1589H18.3411C18.3411 22.3849 15.726 25 12.5 25C9.27404 25 6.65887 22.3849 6.65887 19.1589C6.65887 15.9329 9.27404 13.3177 12.5 13.3177V17.5234C11.5967 17.5234 10.8645 18.2556 10.8645 19.1589C10.8645 20.0621 11.5967 20.7944 12.5 20.7944Z" fill="url(#paint0_linear_1642_2120)" fill-opacity="0.7"/>
    <defs>
    <linearGradient id="paint0_linear_1642_2120" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
    <stop stop-color="#C0DBFF"/>
    <stop offset="1" stop-color="#1677F7"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default Standered
import React from 'react'

const OneDriveIcon = () => {
  return (

    <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 10C18.48 10 14.14 14.48 14 20H14C9.58 20 6 23.58 6 28C6 32.42 9.58 36 14 36H35C39.42 36 43 32.42 43 28C43 23.58 39.42 20 35 20H34.94C34.46 14.72 29.92 10 24 10Z" fill="#0078D4" />
      <path d="M16 30C16 32.2 17.8 34 20 34H34C36.2 34 38 32.2 38 30C38 27.8 36.2 26 34 26H31.28C29.94 24.78 28.1 24 26 24C22.96 24 20.34 26.06 20.06 28.94C17.84 29.16 16 30 16 30Z" fill="#40A6F2" />
    </svg>
  )
}

export default OneDriveIcon
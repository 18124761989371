import React from 'react'

const EnterPriceIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 12.5H0V0C6.90356 6.03529e-07 12.5 5.59644 12.5 12.5ZM25 12.5V0C18.0965 0 12.5 5.59644 12.5 12.5H25ZM12.5 25H0V12.5C6.90356 12.5 12.5 18.0965 12.5 25ZM12.5 25H25V12.5C18.0965 12.5 12.5 18.0965 12.5 25Z" fill="url(#paint0_linear_1642_2157)" fill-opacity="0.7" />
            <defs>
                <linearGradient id="paint0_linear_1642_2157" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C0DBFF" />
                    <stop offset="1" stop-color="#1677F7" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default EnterPriceIcon
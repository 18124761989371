import React from 'react'

const Proffesional = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.508 17.992L14.0159 12.5L19.508 7.00795L25 12.5L19.508 17.992ZM5.49205 17.992L0 12.5L5.49205 7.00795L10.9841 12.5L5.49205 17.992ZM12.5 25L7.00795 19.508L12.5 14.0159L17.992 19.508L12.5 25ZM12.5 10.9841L7.00795 5.49205L12.5 0L17.992 5.49205L12.5 10.9841Z" fill="url(#paint0_linear_1642_2141)" fill-opacity="0.7" />
            <defs>
                <linearGradient id="paint0_linear_1642_2141" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C0DBFF" />
                    <stop offset="1" stop-color="#1677F7" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Proffesional